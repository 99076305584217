import React from "react"
import { Link } from "gatsby"
import {
  proverka,
  proverkaMini,
  play,
  receipt,
  easy,
  secure,
  gerb,
} from "../assets"
import "./main.css"

const Check = () => {
  return (
    <div className="flex pl6-l pl5-m pl4 pr6-l pr3-m pr2">
      <div className="flex-l dn">
        <img
          alt="фото как проверить и оплатить штраф ПДД с телефона"
          title="проверка штрафов ПДД в Казахстане через смартфон"
          src={proverka}
          className="flex-ns mb0  image__size"
        />
      </div>
      <div className="flex flex-column pt6 mb5 w-60-l w-100 ml6-l f4-ns f5 lh-copy">
        <h1 className="flex-wrap w-100-ns w-80 f1-l f2-m f3 ipad__font__header b mb4">
          Проверяйте и оплачивайте штрафы ПДД онлайн
        </h1>
        <Link className=" flex link blue f4-ns f5 items-center">
          <img alt="play" title="play" src={play} className=" w2 h2 mr3 mb0" />
          <span>Как проверить и оплатить штраф?</span>
        </Link>
        <p className="mt4 v-mid ipad__font__text">
          OKauto — это приложение, в котором можно легко,
           быстро и бесплатно проверить наличие штрафов по ИИН, 
           а потом оплатить их онлайн. Чтобы получить информацию 
           о ваших нарушениях, приложение отправляет запрос в 
           государственный источник: <br />
          <img
            alt="gerb"
            title="gerb"
            src={gerb}
            className=" mb0 ml3-ns mt3 gerb__style"
          />{" "}
          Единый реестр административных производств (ЕРАП)
        </p>

        <img
          alt="proverkaMini"
          title="proverkaMini"
          src={proverkaMini}
          className="dn-l mb-0"
        />
        <div className="flex flex-row-ns flex-column items-center-ns">
          <img
            alt="tick1"
            title="easy"
            src={easy}
            className="main__pages__icons mr3"
          />
          <h2 className="f3-l f4-m f5 ">Простое погашение штрафов </h2>
        </div>
        <p className="gray ipad__font__text">
          Благодаря интеграции с Платежным шлюзом электронного правительства РК, 
          мы можем гарантировать, что после оплаты штрафа ваши средства поступят 
          в Комитет Казначейства МФ РК. Штраф будет удален из базы.
        </p>
        <div className="flex flex-row-ns flex-column items-center-ns">
          <img
            alt="secure"
            title="secure"
            src={secure}
            className="main__pages__icons mr3"
          />
          <h2 className="f3-l f4-m f5">Безопасность</h2>
        </div>
        <p className="gray ipad__font__text">
          Для оплаты вы можете использовать карту любого банка от 
          Visa и Mastercard.  Конфиденциальность и безопасность ваших 
          данных будет обеспечена этими системами на территории нашего государства.
        </p>
        <div className="flex flex-row-ns flex-column items-center-ns">
          <img
            alt="receipt"
            title="receipt"
            src={receipt}
            className="main__pages__icons mr3"
          />
          <h2 className="f3-l f4-m f5">Чек-квитанция об оплате</h2>
        </div>
        <p className="gray ipad__font__text">
          После того, как вы оплатите штраф через приложение, 
          вы получите электронную квитанцию. Ее можно распечатать 
          и использовать как банковский документ.
        </p>
      </div>
    </div>
  )
}

export default Check
